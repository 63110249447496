// import React, { Suspense, useRef, useState } from 'react';
// import { Canvas, useFrame } from '@react-three/fiber';
// import { Stars, OrbitControls } from '@react-three/drei';
// import { Friend } from '../models/Friend';
// import notFound from '../assets/images/404.png';
// import { NavLink } from 'react-router-dom';

// const Starfield = () => {
//       const [isRotating, setIsRotating] = useState(false);

//     const RotatingStars = () => {
//         const starsRef = useRef();
//         useFrame(() => {
//         starsRef.current.rotation.x = starsRef.current.rotation.y += 0.00015;
//         });

//         return <Stars ref={starsRef} />;
//     };

//     const adjustFriendsForScreenSize = () => {
//         let screenScale, screenPosition, rotation;
//         if (window.innerWidth < 768) {
//         screenScale = [0.9, 0.9, 0.9];
//         screenPosition = [0, -6.5, -43];
//         rotation = [0.1, 4.7, 0];
//         } else {
//         screenScale = [1, 1, 1];
//         screenPosition = [0, -6.5, -43];
//         rotation = [0.1, 4.7, 0];
//         }
//         return [screenScale, screenPosition, rotation];
//     };

//     const [friendsScale, friendsRotation] = adjustFriendsForScreenSize();
//   return (
//         <div style={{ position: 'relative' }}>
//             <div className='absolute px-5 lg:left-24 lg:top-32 z-10'>
//             <img
//               src={notFound}
//               alt="404 not found"
//               className='w-[300px] '
//             />
//             <h1 className="text-3xl mt-10 font-bold">Page Lost In The Space</h1>
//             <p className="mt-5"><NavLink to='/' className="underline-custom underline-offset-2"><strong>Click here</strong></NavLink> to make a hyperspace jump back to home.</p>
//             </div>

//           <Canvas 
//             camera={{ position: [0, 0, 5], near: 0.1, far: 1000 }} 
//             style={{ position: 'absolute', minHeight: '100vh', marginTop: '-130px', zIndex: -1  }}
//           >
//             <Suspense fallback={null}>
//             <directionalLight position={[1, 1, 1]} intensity={2} />
//             <ambientLight intensity={0.5} />
//             <hemisphereLight skyColor={'#b1e1ff'} groundColor={'#b97a20'} intensity={1} />
//             <RotatingStars />
//             <Friend
//               isRotating={isRotating}
//               position={[0, 0, -10]} 
//               rotation={friendsRotation}
//               scale={friendsScale}
//             />
//             <OrbitControls 
//               enableZoom={false} 
//               enablePan={false} 
//               maxPolarAngle={Math.PI / 2}
//               minPolarAngle={Math.PI / 3}
//             />
//             </Suspense>
//         </Canvas> 
// </div>
//   )
// }

// export default Starfield


import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { Stars, OrbitControls } from '@react-three/drei';
import { Friend } from '../models/Friend';
import notFound from '../assets/images/404.png';
import { NavLink } from 'react-router-dom';

const Starfield = () => {
    const adjustFriendsForScreenSize = () => {
        if (window.innerWidth < 768) {
            return [0.9, 0.9, 0.9];
        } else {
            return [1, 1, 1];
        }
    };

    const friendsScale = adjustFriendsForScreenSize();

    return (
        <div style={{ position: 'relative' , cursor: 'grab'}}>
            <div className='absolute px-5 lg:left-24 lg:top-32 z-10'>
                <img src={notFound} alt="404 not found" className='w-[300px]' />
                <h1 className="text-3xl mt-10 font-bold">Page Lost In The Space</h1>
                <p className="mt-5">
                    <NavLink to='/' className="underline-custom underline-offset-2">
                        <strong>Click here</strong>
                    </NavLink> to make a hyperspace jump back to home.
                </p>
            </div>

            <Canvas 
                camera={{ position: [0, 0, 5], near: 0.1, far: 1000 }} 
                style={{ position: 'absolute', minHeight: '100vh', marginTop: '-130px', zIndex: -1 }}
            >
                <Suspense fallback={null}>
                    <directionalLight position={[1, 1, 1]} intensity={2} />
                    <ambientLight intensity={0.5} />
                    <hemisphereLight skyColor={'#b1e1ff'} groundColor={'#b97a20'} intensity={1} />
                    <Stars />
                    <Friend scale={friendsScale} />
                    <OrbitControls 
                        enableZoom={false} 
                        enablePan={false} 
                        maxPolarAngle={Math.PI / 2}
                        minPolarAngle={Math.PI / 3}
                    />
                </Suspense>
            </Canvas> 
        </div>
    );
}

export default Starfield;
