import React from 'react';
import { dataSoftwareTestingFirst, dataSoftwareTestingSecond } from '../data/dataSoftwareTesting';
import testServiceFirst from '../assets/images/testing-service-first.png';
import testServiceSecond from '../assets/images/testing-service-second.png';
import CTA from "../components/CTA";
import Footer from '../components/Footer';
import { NavLink } from 'react-router-dom';

const SoftwareTesting = () => {
    return (
    <>
    <section className= "testing-bg pb-5 pt-44 lg:pt-32">
            <div className='px-5 lg:px-24'>
                <h1 className="text-4xl font-bold">Services: Software Testing</h1>
                <p className='mt-5'>At SpaceWeb, we understand that the quality of your software directly impacts your business success and customer satisfaction. Our comprehensive software testing services are designed to ensure that your applications meet the highest standards of quality, functionality, and reliability. We leverage the latest testing methodologies and tools to uncover any issues before they affect your users, ensuring your software performs flawlessly under all conditions. Here's an overview of our software testing services:</p>
            </div>
            <div className="flex flex-col-reverse lg:flex-row">
                <div>
                    {dataSoftwareTestingFirst.map((section, index) => (
                        <div key={section.id} className="flex flex-col px-5 lg:pl-24 lg:pr-10">
                            <div className='flex flex-col'>
                                <h2 className="text-2xl font-semibold mt-10">{section.header}</h2>
                                <p>{section.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <img
                    src={testServiceFirst}
                    alt="Feature"
                    className="w-full md:w-1/2 lg:w-1/3 float-left  mb-4 h-auto rounded-lg shadow-lg mt-5"
                />
            </div>

    <div className='flex flex-col lg:flex-row mt-10'>
        <img
              src={testServiceSecond}
              alt="Feature"
              className="w-full md:w-1/2 lg:w-1/3 float-right h-auto rounded-lg shadow-lg"
            />
            <div>
    {dataSoftwareTestingSecond.map((section, index) => (
        <div key={section.id} className="flex flex-col">
          <div className='flex flex-col px-5 lg:pr-24 lg:pl-10'>
            <h2 className="text-2xl font-semibold mt-10">{section.header}</h2>
            <p className='mt-5'>{section.content}</p>
          </div>
        </div>
      ))}
    </div>
    </div>

    <p className='px-5 lg:px-24 py-10'>At <span className='text-gradient '>SpaceWeb</span>, we're passionate about web development and committed to delivering solutions that not only fulfill your digital needs but also propel your business forward. Let's collaborate to build a website that captures the essence of your brand and serves as a cornerstone for your digital strategy.</p>
    <NavLink to='/contact'>
      <CTA text="Contact Us" />
    </NavLink>
    </section>
    <Footer />
    </>
  );
};

export default SoftwareTesting;

