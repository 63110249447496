import React from 'react'
import Footer from '../components/Footer'
import { NavLink } from 'react-router-dom'

const PrivacyPolicy = () => {
  return (
      <section className='testing-bg pt-44 lg:pt-32'>
        <div className='px-5 lg:px-24'>
        <h1 className="text-3xl font-bold">Privacy Policy:</h1>
        <p className="my-10">This Privacy Policy was last updated on March 10, 2024</p>
        <ol className="">
            <li><strong>Personal Information:</strong> We may collect personal information that you provide to us such as your name, email address, phone number, and any other information you choose to provide.</li>
            <li><strong>How We Use Your Information: </strong> The information we collect is used in various ways, including to:
              <ul>
                <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                <li>Send you emails</li>
                <li>Find and prevent fraud</li>
              </ul>
            </li>
            <li><strong>Sharing Your Personal Information: </strong> We do not sell, trade, or rent your personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.</li>
            <li><strong>Security of Your Personal Information:</strong> The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</li>
            <li><strong>Changes to This Privacy Policy:</strong> We may update our Privacy Policy from time to time. We will notify you of any changes by posting aware that your child has provided us with personal information, please contact us. If we become aware that we have collected personal information from children without verification of parental consent, we take steps to remove that information from our servers.</li>
            <li><strong>Contact Us:</strong> If you have any questions or suggestions about our Privacy Policy, do not hesitate to <NavLink to='/contact' className='text-blue-500 underline'>contact us</NavLink>. </li>
        </ol>
        <p className="my-10">By using our Service, you agree to the collection and use of information in accordance with this policy. </p>
        </div>
        <Footer />
  </section>
    )
}

export default PrivacyPolicy