import image from '../../assets/images/hero-abstract-lines.png';
import { data } from './data';
import CTA from '../CTA';
import { NavLink } from 'react-router-dom';



const HomeInfo = () => {

    return (
        <section className='info-section-bg pb-10'>
            <div className=' px-5 lg:px-24 py-10'>
                <p>Welcome to <span className='text-gradient'>SpaceWeb</span>, where we bring your digital visions to life. Our commitment to excellence and innovation has made us a leader in web development and testing services. </p>
            </div>
            <div className='flex flex-col items-center lg:flex-row'>
                <img 
                    className='w-full md:w-1/2 lg:w-1/3 float-right h-auto shadow-lg'
                    src={image} 
                    alt="" 
                />
                <div className='flex flex-col gap-4 lg:ml-10 px-5 py-10 lg:pr-24 lg:p-0'>
                {data.map((data) => {
                    return (
                        <div key={data.id} className=''>
                            <h2 className='text-2xl mt-5'>{data.heading}</h2>
                            <p>{data.text}</p>
                        </div>
                    )
                })}
                </div>
            </div>
            <div className='px-5 lg:px-24 py-10'>
                <p>At <span className='text-gradient'>SpaceWeb</span>, we believe in partnering with our clients to turn their ideas into reality. With a focus on innovation, quality, and customer satisfaction, we are dedicated to delivering solutions that not only meet but exceed your expectations. Let's work together to create something remarkable.</p>
            </div>
            <NavLink 
                to='/about' 
                title='Go to About page'
                aria-label='Learn more about our company'
            >
            <CTA 
                text='More About' 
            />
            </NavLink>
        </section>
    )
} 

export default HomeInfo;