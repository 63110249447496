import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  return (
    <header className="flex justify-between items-center py-7 px-5 md:px-24 " >
      <NavLink to="/" className="navigation text-[40px] text-gradient py-5">
        SpaceWeb
      </NavLink>

      <nav className="hidden md:flex text-lg gap-7 font-medium">
        <NavLink to="/about" className={({ isActive }) => isActive ? 'text-pink-500' : 'text-white'}>
          About
        </NavLink>
        <div className='group'>
              <span className='text-white cursor-pointer'>Services</span>
                <div className='absolute hidden group-hover:block bg-pink-500 px-5 text-white rounded-lg'>
                    <NavLink to='/web-development' className='block py-2 hover:text-black'>Web&nbsp;Development</NavLink>
                    <NavLink to='/software-testing' className='block py-2 hover:text-black'>Software&nbsp;Testing</NavLink>
                </div>
              </div>
        <NavLink to="/faq" className={({ isActive }) => isActive ? 'text-pink-500' : 'text-white'}>
          FAQ
        </NavLink>
        <NavLink to="/contact" className={({ isActive }) => isActive ? 'text-pink-500' : 'text-white'}>
          Contact
        </NavLink>
      </nav>
    </header>
  );
};

export default Navbar;
