import { useState } from 'react';
import { FAQ_Component } from '../components/FAQ_Component';
import { dataFAQ }from '../data/dataFAQ';
import Footer from '../components/Footer';
import CTA from '../components/CTA';
import { NavLink } from 'react-router-dom';

export const FAQ = () => {
        const [openId, setOpenId] = useState(null);
    
        const toggle = (id) => {
            setOpenId(openId === id ? null : id);
        } 
    return (
        <>
        <section className="px-5 lg:px-24 faq-section pb-10"> 
            <div className='pt-20'>
                <h1 className="text-3xl font-bold mt-28">FAQ: Frequently Asked Questions</h1>
                <p className="mt-5 mb-16">Here are 15 of the most frequently asked questions along with their answers to help you understand more about our services, processes, and what you can expect when working with <span className="text-gradient">SpaceWeb</span>.</p>
                <div >
                    {dataFAQ.map((item, index ) => 
                    // eslint-disable-next-line 
                        <FAQ_Component
                            key={item.id}
                            question={item.question}
                            answer={item.answer}
                            isOpen={openId === item.id}
                            toggle={() => toggle(item.id)}
                        />
                        )}
                </div>
            </div>
            <div className='mt-10'>
                <p className='text-xl text-center mb-5'>Still have a question?</p>
                <NavLink to='/contact'>
                    <CTA text="Contact Us" />
                </NavLink>
            </div>
        </section>
        <Footer />
        </>
    )
}