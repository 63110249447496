/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Author: LowPolyBoy (https://sketchfab.com/lowPolyBoy)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/space-adventure-2c1541b4d8084bef95417bca7702eb7b
Title: Space Adventure
*/
import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from '@react-three/fiber';
import spaceAdventure from '../assets/models/space_adventure.glb';

import { a } from "@react-spring/three";


export function Friend({ isRotating, speed, ...props }) {
    const { nodes, materials } = useGLTF(spaceAdventure);
    const ref = useRef();

  const [friendsScale, setFriendsScale] = useState([1, 1, 1]);
  const [friendsPosition, setFriendsPosition] = useState([0, 0, 0]);
  const [friendsRotation, setFriendsRotation] = useState([0, 0, 0]);

  const adjustFriendsForScreenSize = () => {
    let scale = [1, 1, 1];
    let position, rotation;
  
    if (window.innerWidth < 1025) {
      scale = [0.9, 0.9, 0.9];
      position = [0, -2, 0]; // смещаем вниз на мобильных
      rotation = [0, 0, 0]; // сохраняем прямой поворот
    } else {
      position = [2, 0, 0]; // смещаем вправо на десктопных
      rotation = [0, 0, 0]; // сохраняем прямой поворот
    }
  
    return [scale, position, rotation];
  };
  
  
  useEffect(() => {
    const handleResize = () => {
      const [scale, position, rotation] = adjustFriendsForScreenSize();
      setFriendsScale(scale);
      setFriendsPosition(position);
      setFriendsRotation(rotation);
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useFrame((state, delta) => {
    if (isRotating && ref.current) {
      ref.current.rotation.y += delta * speed; 
    }
  });


  return (
    <a.group
    ref={ref}
    {...props}
    scale={friendsScale}
    position={friendsPosition}
    rotation={friendsRotation}
    dispose={null}
  >
      <group
        position={[0.258, 1.154, -0.011]}
        rotation={[-0.009, -0.718, 0.077]}
        scale={1.011}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_14.geometry}
          material={materials.Bucket}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_15.geometry}
          material={materials["Bucket.001"]}
        />
      </group>
      <group
        position={[0.267, 0.966, 0.002]}
        rotation={[0.133, -0.705, -2.972]}
        scale={0.94}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17.geometry}
          material={materials["Bucket.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_18.geometry}
          material={materials["Bucket.003"]}
        />
      </group>
      <group
        position={[0.348, 0.887, 0.076]}
        rotation={[2.541, -0.333, -2.972]}
        scale={[0.174, 0.174, 0.121]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_32.geometry}
          material={materials["CardBoardFront.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_33.geometry}
          material={materials["CardBoardBack.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_34.geometry}
          material={materials["cardboardSide.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_35.geometry}
          material={materials["ship_decal_color.001"]}
        />
      </group>
      <group
        position={[0.339, 0.843, 0.096]}
        rotation={[2.973, -0.277, -3.031]}
        scale={[0.174, 0.174, 0.121]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_37.geometry}
          material={materials["CardBoardFront.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_38.geometry}
          material={materials["CardBoardBack.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_39.geometry}
          material={materials["cardboardSide.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_40.geometry}
          material={materials["ship_decal_color.001"]}
        />
      </group>
      <group
        position={[0.325, 0.793, 0.1]}
        rotation={[-3.006, -0.361, -2.543]}
        scale={[0.21, 0.21, 0.146]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_42.geometry}
          material={materials["CardBoardFront.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_43.geometry}
          material={materials["CardBoardBack.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_44.geometry}
          material={materials["cardboardSide.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_45.geometry}
          material={materials["ship_decal_color.001"]}
        />
      </group>
      <group
        position={[0.483, 0.445, 0.146]}
        rotation={[-0.665, 0.756, 0.165]}
        scale={0.443}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_59.geometry}
          material={materials.Gradient}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_60.geometry}
          material={materials["Bucket.001"]}
        />
      </group>
      <group
        position={[-0.059, 0.836, 0.171]}
        rotation={[-2.948, -0.914, 3.06]}
        scale={[0.021, 0.021, 0.013]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_86.geometry}
          material={materials.material_20}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_87.geometry}
          material={materials.Gun2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_88.geometry}
          material={materials.Gun3}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_89.geometry}
          material={materials.Gun4}
        />
      </group>
      <group
        position={[-0.293, 0.278, -0.037]}
        rotation={[-1.335, 0.655, 2.553]}
        scale={0.571}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_93.geometry}
          material={materials.NurdBullet}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_94.geometry}
          material={materials.NurdBullet}
        />
      </group>
      <group
        position={[1.104, 0, -0.552]}
        rotation={[0, -0.622, 0]}
        scale={0.918}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_98.geometry}
          material={materials["CardBoardFront.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_99.geometry}
          material={materials["CardBoardBack.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_100.geometry}
          material={materials["cardboardSide.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_101.geometry}
          material={materials["ductape_color.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_102.geometry}
          material={materials["ship_decal_color.002"]}
        />
      </group>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.677}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_41.geometry}
          material={materials.tbpjr_f_astro}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_41_1.geometry}
          material={materials.Medyas}
        />
      </group>
      <group
        position={[1.26, -0.008, 0.399]}
        rotation={[0, -1.073, 0]}
        scale={0.016}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_109.geometry}
          material={materials["Dog.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_110.geometry}
          material={materials["Clothes_dog.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_111.geometry}
          material={materials["Shoe.001"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_4.geometry}
        material={materials.Damit}
        position={[0, 0.772, 0.03]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_6.geometry}
        material={materials.Damit}
        position={[0, 0.772, 0.03]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_8.geometry}
        material={materials.Belt}
        position={[0.181, 0.608, 0.022]}
        rotation={[-0.102, -0.042, -0.445]}
        scale={1.092}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_10.geometry}
        material={materials.HairMatAniso}
        position={[0.249, 1.15, 0]}
        rotation={[0.14, -0.704, 0.174]}
        scale={0.159}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_12.geometry}
        material={materials.HairMatAniso}
        position={[0.249, 1.15, 0]}
        rotation={[0.14, -0.704, 0.174]}
        scale={0.159}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_20.geometry}
        material={materials.material}
        position={[0.26, 0.684, -0.269]}
        rotation={[-0.15, -0.103, -0.11]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_22.geometry}
        material={materials.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_24.geometry}
        material={materials.material}
        position={[0.252, 0.602, -0.27]}
        rotation={[-0.15, -0.103, -0.11]}
        scale={[0.576, 0.343, 0.489]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_26.geometry}
        material={materials.material}
        position={[0.151, -0.006, 0.097]}
        rotation={[-0.15, -0.103, -0.11]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_28.geometry}
        material={materials.ductape_color}
        position={[0.151, -0.006, 0.097]}
        rotation={[-0.15, -0.103, -0.11]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_30.geometry}
        material={materials["Bucket.004"]}
        position={[0.151, -0.006, 0.097]}
        rotation={[-0.15, -0.103, -0.11]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_47.geometry}
        material={materials.iceCandy}
        position={[0.3, 0.555, 0.102]}
        rotation={[-0.197, -0.174, -0.132]}
        scale={0.012}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_49.geometry}
        material={materials.iceCandy}
        position={[0.273, 0.57, 0.136]}
        rotation={[-0.254, 0.02, -0.26]}
        scale={0.012}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_51.geometry}
        material={materials.iceCandy}
        position={[0.239, 0.566, 0.159]}
        rotation={[-0.292, -0.009, -0.271]}
        scale={0.012}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_53.geometry}
        material={materials.Bread}
        position={[0.47, 0.412, 0.145]}
        rotation={[1.119, 0.023, -0.77]}
        scale={0.057}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_55.geometry}
        material={materials.Bread}
        position={[0.496, 0.424, 0.169]}
        rotation={[1.119, 0.023, -0.77]}
        scale={0.057}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_57.geometry}
        material={materials.Gradient}
        position={[0.483, 0.445, 0.146]}
        rotation={[-0.665, 0.756, 0.165]}
        scale={0.443}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_62.geometry}
        material={materials.Gradient}
        position={[0.483, 0.445, 0.146]}
        rotation={[-0.665, 0.756, 0.165]}
        scale={0.522}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_64.geometry}
        material={materials.Gradient}
        position={[0.461, 0.55, 0.074]}
        rotation={[-0.665, 0.756, 0.165]}
        scale={0.522}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_66.geometry}
        material={materials.Food}
        position={[0.538, 0.396, 0.131]}
        rotation={[2.054, 0.7, 1.997]}
        scale={[-0.032, 0.005, 0.032]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_68.geometry}
        material={materials.Food}
        position={[0.531, 0.443, 0.115]}
        rotation={[2.054, 0.7, 1.997]}
        scale={[-0.031, 0.005, 0.031]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_70.geometry}
        material={materials.Food}
        position={[0.46, 0.484, 0.171]}
        rotation={[1.422, 0.318, 2.319]}
        scale={[-0.031, 0.005, 0.031]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_72.geometry}
        material={materials.Food}
        position={[0.453, 0.426, 0.208]}
        rotation={[0.577, -0.473, 2.244]}
        scale={[-0.031, 0.005, 0.031]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_74.geometry}
        material={materials.Food}
        position={[0.435, 0.464, 0.189]}
        rotation={[1.486, 0.511, -0.688]}
        scale={[0.039, 0.018, 0.039]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_76.geometry}
        material={materials.Food}
        position={[0.466, 0.49, 0.142]}
        rotation={[1.253, 0.305, -0.625]}
        scale={[0.039, 0.018, 0.039]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_78.geometry}
        material={materials.Food}
        position={[0.52, 0.461, 0.097]}
        rotation={[0.799, -0.152, -0.658]}
        scale={[0.039, 0.018, 0.039]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_80.geometry}
        material={materials.Food}
        position={[0.529, 0.39, 0.125]}
        rotation={[-1.037, -0.605, -1.815]}
        scale={[0.039, 0.018, 0.039]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_82.geometry}
        material={materials.SpaceFood}
        position={[0.495, 0.424, 0.219]}
        rotation={[0.993, 0.092, -0.76]}
        scale={0.061}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_84.geometry}
        material={materials.Bear}
        position={[0.102, 0.582, 0.201]}
        rotation={[-0.419, -0.242, -0.339]}
        scale={0.427}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_91.geometry}
        material={materials.NurdBullet}
        position={[-0.523, 0.776, 0.785]}
        rotation={[0.137, 0.923, 1.662]}
        scale={0.571}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_96.geometry}
        material={materials.Shoe}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_104.geometry}
        material={materials.material_31}
        position={[0.217, 1.107, 0.042]}
        rotation={[0.259, -0.684, 0.25]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_113.geometry}
        material={materials["Bucket.005"]}
        position={[0.989, 0.495, 0.434]}
        rotation={[1.624, -1.172, 1.556]}
        scale={0.616}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_120.geometry}
        material={materials.SpeedTrail}
        position={[-0.819, 0.679, 1.166]}
        rotation={[-2.89, 0.645, -1.64]}
        scale={[0.241, 0.241, 0.449]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_122.geometry}
        material={materials.NurdBullet}
        position={[-1.023, 0.611, 1.428]}
        rotation={[0.137, 0.923, 1.662]}
        scale={0.571}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_124.geometry}
        material={materials.SpeedTrail}
        position={[-0.319, 0.844, 0.522]}
        rotation={[-2.89, 0.645, -1.64]}
        scale={[0.241, 0.241, 0.449]}
      />
    </a.group>
  );
}



