import React from 'react';
import { dataWebDevelopment } from '../data/dataWebDevelopment';
import webServiceFirst from '../assets/images/web-service-first.png';
import webServiceSecond from '../assets/images/web-service-second.png';
import CTA from "../components/CTA";
import Footer from '../components/Footer';
import { NavLink } from 'react-router-dom';

const WebDevelopment = () => {
  return (
    <>
    <section className="web-dev-bg pb-5 pt-44 lg:pt-32">
      <div className='flex flex-col lg:flex-row'>
        <img
                src={webServiceFirst}
                alt="Feature"
                className="w-full md:w-1/2 lg:w-1/3 float-left mr-8 mb-4 h-auto rounded-lg shadow-lg"
              />
        <div className="flex flex-col mb-8 px-5 pt-10 lg:pr-24">
          <h1 className="text-4xl font-bold">Services: Web Development</h1>
          <p className='mt-5'>At SpaceWeb, our web development services are at the heart of our digital offerings. We understand the critical role your website plays in your business's success, serving as your virtual storefront, brand ambassador, and primary customer engagement platform. Our goal is to create bespoke web solutions that not only meet but exceed your expectations, driving growth and enhancing your online presence. Here's an overview of our web development services:</p>

          <h2 className="text-2xl font-semibold mt-10">Custom Website Design</h2>
          <p>We believe in creating unique websites that stand out. Our custom design process involves understanding your brand, target audience, and objectives to craft a visually stunning and user-friendly site. From sleek corporate websites to vibrant creative portfolios, our designs are tailored to reflect your identity and engage your visitors.</p>

          <h2 className="text-2xl font-semibold mt-10">E-commerce Solutions</h2>
          <p>In the ever-evolving digital marketplace, having a robust e-commerce platform is essential. We specialize in developing e-commerce websites that are secure, scalable, and user-centric, offering seamless shopping experiences. Whether you're a startup or an established retailer, our solutions are designed to boost your online sales and streamline your operations.</p>
        </div>
      </div>

    <div className='flex flex-col-reverse lg:flex-row'>
    <div>
    {dataWebDevelopment.map((section, index) => (
        <div key={section.id} className="flex flex-col px-5 lg:pl-24">
          <div className='flex flex-col'>
            <h2 className="text-2xl font-semibold mt-10">{section.header}</h2>
            <p>{section.content}</p>
          </div>
        </div>
      ))}
    </div>
        <img
              src={webServiceSecond}
              alt="Feature"
              className="w-full md:w-1/2 lg:w-1/3 float-right lg:ml-8 mb-4 h-auto rounded-lg shadow-lg"
            />
    </div>

    <p className='px-5 lg:px-24 py-10'>At <span className='text-gradient '>SpaceWeb</span>, we're passionate about web development and committed to delivering solutions that not only fulfill your digital needs but also propel your business forward. Let's collaborate to build a website that captures the essence of your brand and serves as a cornerstone for your digital strategy.</p>
    <NavLink to='/contact'>
      <CTA text="Contact Us" />
    </NavLink>
    </section>
    <Footer />
    </>
  );
};

export default WebDevelopment;

