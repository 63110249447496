export const data = [
    {
        id: 1,
        heading: 'Web Development',
        text: 'We specialize in creating responsive, high-performance websites tailored to your unique requirements. From custom website design to e-commerce solutions, our team uses the latest technologies and frameworks to build websites that not only look great but also perform exceptionally on all devices.'
    },
    {
        id: 2,
        heading: 'Application Development',
        text: 'Our application development services are focused on delivering custom, scalable, and robust web applications. We work with a variety of technologies to create applications that improve business operations, enhance user experiences, and drive customer engagement.'
    },
    {
        id: 3,
        heading: 'Software Testing and Quality Assurance',
        text: 'Quality is at the heart of everything we do. Our comprehensive testing and quality assurance services ensure that your software is reliable, secure, and meets the highest standards of quality. From automated testing to manual quality checks, we cover all aspects to minimize risks and enhance performance.'
    },
    {
        id: 4,
        heading: 'Software Testing and Quality Assurance',
        text: "Our job doesn't end with the launch of your project. We provide ongoing support and maintenance services to ensure your website or application continues to operate smoothly, remains up-to-date, and adapts to changing requirements over time."
    },
]