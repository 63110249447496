import React from 'react'
import Footer from '../components/Footer';
import { NavLink } from 'react-router-dom'

const TermsOfUse = () => {
  return (
    <section className='testing-bg pt-44 lg:pt-32'>
      <div className='px-5 lg:px-24 mb-10'>
        <h1 className="text-3xl font-bold">Terms of Use:</h1>
            <p className="my-10">This Terms of Use was last updated on March 10, 204</p>
            <ol className="">
                <li><strong>Acceptance of Terms:</strong> By using our website and services, you confirm that you accept these Terms of Use and agree to comply with them. If you do not agree to these terms, you must not use our website or services.</li>
                <li><strong>Changes to Terms:</strong> SpaceWeb reserves the right to modify these Terms of Use at any time. We will notify you of any changes by posting the new terms on this site. Your continued use of the website and services following any changes means that you accept and agree to the changes.</li>
                <li><strong>Access and Use of the Service:</strong> 
                  <ul>
                    <li>You are responsible for making all arrangements necessary for you to have access to our website.</li>
                    <li>We reserve the right to withdraw or amend our service without notice.</li>
                    <li>We may restrict access to some parts of our website, or our entire site, to users, including registered users.</li>
                  </ul>
                </li>
                <li><strong>Intellectual Property Rights:</strong> 
                  <ul>
                    <li>All intellectual property rights in our website and the material on our website are owned by us or our licensors.</li>
                    <li>You may print off one copy, and may download extracts, of any page(s) from our website for your personal use and you may draw the attention of others within your organization to content posted on our website.</li>
                    <li>You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</li>
                  </ul>
                </li>
                <li><strong>Prohibited Uses:</strong> You may use our website only for lawful purposes. You may not use our website:
                  <ul>
                    <li>In any way that breaches any applicable local, national or international law or regulation.</li>
                    <li>In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.</li>
                    <li>To transmit, or procure the sending of, any unsolicited or unauthorized advertising or promotional material or any other form of similar solicitation (spam).</li>
                    <li>To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware, or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</li>
                  </ul>
                </li>
                <li><strong>Termination:</strong> We may terminate or suspend your access to our website and services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms of Use.</li>
                <li><strong>Disclaimer of Warranties:</strong> Our website and services are provided on an "as is" and "as available" basis. SpaceWeb Solutions makes no representations or warranties of any kind, express or implied, as to the operation of their services, or the information, content, materials, or products included on this website.</li>
                <li><strong>Limitation of Liability:</strong> In no event will SpaceWeb Solutions be liable for any damages, including without limitation, direct, indirect, special, punitive, incidental or consequential damages, or damages for loss of use, profits, data or other intangibles, in connection with the use, inability to use, or performance of the website and services.</li>
                <li><strong>Governing Law:</strong> These Terms of Use shall be governed by and construed in accordance with the laws of British Columbia, Canada, without giving effect to any principles of conflicts of law.</li>
                <li><strong>Contact Us:</strong> If you have any questions about these Terms of Use, please <NavLink to='/contact' className='text-blue-500 underline'>contact us</NavLink>.</li>
            </ol>
      </div>
      <Footer />

    </section>
  )
}

export default TermsOfUse