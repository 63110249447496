import { useNavigate } from 'react-router';


const HeroButton = () => {
    const navigate = useNavigate();
  return (
    <div className='bg-gradient-to-r from-[#FA5AA5] to-[#612ABA]  rounded-full w-60 flex justify-center mt-10 ml-5 lg:ml-24 cta-btn hover:bg-transparent transition-colors duration-900'>
        <button 
            className='font-bold text-xl py-4 '
            onClick={()=> navigate('/contact')}
        >
            Contact Us
        </button>
    </div>
  )
}

export default HeroButton;