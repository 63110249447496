import React from 'react';
import contactImg from '../assets/images/contact.png';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';

const Contact = () => {
  return (
    <>
    <section className='min-h-screen bg-custom-about'>
      <div className='px-5 lg:px-24  pt-44 lg:pt-32'>
        <h1 className='text-3xl font-bold'>Contact Us</h1>
        <p className='mt-5'>Welcome to <span className='text-gradient'>SpaceWeb</span>, where innovation meets excellence in web development and software testing. Since our inception in 2022, we have been at the forefront of creating digital solutions that empower businesses, enhance user experiences, and drive technological advancement.</p>
      </div>
      <div className='flex flex-col lg:flex-row items-center'>
        <img 
          src={contactImg} 
          alt="" 
          className='w-full md:w-1/2 lg:w-1/3 float-left lg:mr-8 mb-4 h-auto rounded-lg shadow-lg mt-5'
        />
        <ContactForm />
      </div>
    </section>
          <Footer />
      </>
  )
}

export default Contact;