import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Bars3Icon as MenuIcon, XMarkIcon as XIcon } from '@heroicons/react/24/outline';
import { Transition } from '@headlessui/react';

const NavbarMobile = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div className="md:hidden flex items-center p-5">
    <NavLink to="/" className="navigation text-[40px] text-gradient py-5" onClick={closeMenu}>
      SpaceWeb
    </NavLink>
    <button
      onClick={toggleMenu}
      type="button"
      className="p-2 rounded-md text-white absolute right-5"
      aria-controls="mobile-menu"
      aria-expanded={isOpen}
      aria-label='Mobile menu button'
    >
      {isOpen ? <XIcon className="absolute z-10 right-5 h-10 w-10" /> : <MenuIcon className="h-10 w-10" />}
    </button>

    <Transition
      show={isOpen}
      as={React.Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <nav className={`absolute top-0 left-0 w-full h-full bg-gradient-to-r from-[#FA5AA5] to-[#612ABA] ${isOpen ? 'block' : 'hidden'}`}>
        <div className="px-2 pt-2 pb-3 space-y-1 text-3xl font-medium flex flex-col gap-6 items-center justify-center min-h-screen">
          <NavLink to="/" className={({ isActive }) => isActive ? 'text-shadow' : 'text-white'} onClick={closeMenu}>
            Home
          </NavLink>
          <NavLink to="/about" className={({ isActive }) => isActive ?  'text-shadow' : 'text-white'} onClick={closeMenu}>
            About
          </NavLink>
          <NavLink to='/web-development' className={({ isActive }) => isActive ?  'text-shadow' : 'text-white'} onClick={closeMenu}>
            Web&nbsp;Development
          </NavLink>
          <NavLink to='/software-testing' className={({ isActive }) => isActive ? 'text-shadow' : 'text-white'} onClick={closeMenu}>
            Software&nbsp;Testing
          </NavLink>
          <NavLink to="/faq" className={({ isActive }) => isActive ? 'text-shadow' : 'text-white'} onClick={closeMenu}>
            FAQ
          </NavLink>
          <NavLink to="/contact" className={({ isActive }) => isActive ? 'text-shadow' : 'text-white'} onClick={closeMenu}>
            Contact
          </NavLink>
        </div>
      </nav>
    </Transition>
  </div>
);
};

export default NavbarMobile;
