import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

function ContactForm() {
    const [state, handleSubmit] = useForm("mvononyz");
    if (state.succeeded) {
        return (
            <div className='pr-24'>
                <h2 className='font-semibold text-2xl'>Thank You for Reaching Out!</h2>
                <p className='mt-5'>Your message has been successfully sent. We appreciate you taking the time to contact us.</p>
                <p  className='mt-5'>we value every inquiry and feedback we receive. Our team is currently reviewing your message, and we will be in touch shortly to provide a personalized response.</p>
                <p className='mt-5'>In the meantime, feel free to browse through our website to learn more about our services and insights in the industry. Your interest in our company is greatly appreciated, and we look forward to the opportunity to assist you.</p>
                <p className='mt-5'>Thank you again for contacting <span className='text-gradient'>SpaceWeb</span>!</p>
                <p className='mt-5'>Best regards,
                    <br />The <span className='text-gradient'>SpaceWeb</span> Team</p>
            </div>
        );
    }
    return (
        <form onSubmit={handleSubmit} className='flex flex-col px-5 lg:pr-24 w-full'>
        <label htmlFor="email" className='flex flex-col mt-10'>
            Name
        <input
            id="name"
            type="text" 
            name="name"
            className='input'
            placeholder='john@gmail.com'
            required
            />
            </label>
        <label htmlFor="email" className='flex flex-col mt-10'>
            Email
        <input
            id="email"
            type="email" 
            name="email"
            className='input'
            placeholder='john@gmail.com'
            required
            />
            </label>
        <ValidationError 
            prefix="Email" 
            field="email"
            errors={state.errors}
        />
        <label htmlFor="textarea" className='flex flex-col mt-10'>
        Your Message
        <textarea
            id="message"
            name="message"
            rows={2}
            className='textarea'
            placeholder='Type your message...'
            required
        />
        </label>
        <ValidationError 
            prefix="Message" 
            field="message"
            errors={state.errors}
        />
            <div className='bg-gradient-to-r from-[#FA5AA5] to-[#612ABA] rounded-full flex justify-center mx-auto w-64 py-2 my-10 cta-btn hover:bg-transparent transition-colors duration-900'>
                <button
                    type='submit'
                    className='text-white font-semibold py-4 text-xl rounded-full px-14 disabled:opacity-40'
                    disabled={state.submitting}
                    >Submit
                </button>
            </div>
        </form>
    );
}
export default ContactForm;