import React from 'react';
import logo from '../assets/images/spaceWeb_logo.png';
import { NavLink } from 'react-router-dom';
import iconLinkedIn from '../assets/icons/icon_linkedin.png';
import iconMail from '../assets/icons/mail.png';

const Footer = () => {
  return (
    <footer>
      <div className='bg-gradient-to-r from-[#FA5AA5] to-[#612ABA] px-5 lg:px-24 py-6'>
        <div className='flex flex-col justify-between gap-4 lg:items-center lg:flex-row items-center text-center lg:text-start'>
        <NavLink to='/'>
          <img src={logo} alt="logo" className='w-24 h-24 rounded-full'/>
          </NavLink>

            <nav className='flex flex-col text-xl gap-4'>
              <NavLink to='/about'>About</NavLink>
              <NavLink to='/web-development' >Web&nbsp;Development</NavLink>
              <NavLink to='/software-testing'>Software&nbsp;Testing</NavLink>
              <NavLink to='/faq'>FAQ</NavLink>
            </nav>
            <nav className='flex flex-col text-xl gap-4'>
            <NavLink to='/contact'>Contact Us</NavLink>
              <NavLink to='/privacy-policy'>Privacy Policy</NavLink>
              <NavLink to='/terms-of-use'>Terms of Use</NavLink>
            </nav>
            <div className='flex lg:flex-col'>
              <a href="https://www.linkedin.com/company/spaceweb-solutions">
                <img src={iconLinkedIn} alt="LinkedIn" className='w-14 h-14' />
              </a>
              <a href="mailto:valeriia.code@gmail.com">
                <img src={iconMail} alt="email" className='w-14 h-14' />
              </a>
            </div>
          </div>
      </div>
      <p className='text-sm text-center p-2'>Copyright © 2024 SpaceWeb</p>
    </footer>
  )
}

export default Footer;