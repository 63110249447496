import iconPlus from '../assets/icons/plus.png'

export const FAQ_Component = ({question, answer, isOpen, toggle}) => {
    return(
        <>
            <div 
                className="flex justify-between  mb-5 cursor-pointer"
                onClick={toggle}
            >
                <h3 className="font-semibold text-2xl">{question}</h3>
                <img 
                    src={iconPlus} 
                    alt="" 
                    className={`h-[30px] transition-transform duration-300 ${isOpen ? 'rotate-45' : ''}`}
                    />
            </div>
            <div 
                className={`overflow-hidden transition-max-height duration-300 ${isOpen ? 'max-h-60' : 'max-h-0'}`}
            >
                <p className="mb-5">
                    {answer}
                </p>
            </div>
        </>
    )
}
