import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Navbar from "./components/Navbar";
import './index.css';
import { FAQ } from "./pages/FAQ";
import WebDevelopment from "./pages/WebDevelopment";
import SoftwareTesting from "./pages/SoftwareTesting";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import ScrollToTop from "./components/ScrollToTop";
import { useEffect, useState } from "react";
import NavbarMobile from "./components/NavbarMobile";
import Starfield from './components/Starfield';



function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
<main>
        <Router>
        <ScrollToTop />
        {isMobile ? <NavbarMobile /> :  <Navbar />}
          
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/faq' element={<FAQ />} />
            <Route path='/web-development' element={<WebDevelopment />} />
            <Route path='/software-testing' element={<SoftwareTesting />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-of-use' element={<TermsOfUse />} />
            <Route path='*' element={<Starfield  />} /> 
          </Routes>
        </Router>
      </main>
  );
}

export default App;
