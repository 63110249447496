import HeroButton from '../components/HeroButton';
import HomeInfo from '../components/home/HomeInfo';
import Footer from '../components/Footer';


const Home = () => {
    return (
        <div className='-mt-8'>
        <div className='flex flex-col h-screen min-h-screen bg-custom-image'>
        <h1 className='font-semibold text-4xl px-5 mt-44 lg:text-[48px] lg:mt-36 lg:pl-24'>Here quality <br /> meets innovation.</h1>
        <HeroButton />
        </div>
        <HomeInfo />
        <Footer />
        </div>
    );
};

export default Home;


