export const dataWebDevelopment =[
    // {
    //     id: 1,
    //     header: 'Custom Website Design',
    //     content: 'We believe in creating unique websites that stand out. Our custom design process involves understanding your brand, target audience, and objectives to craft a visually stunning and user-friendly site. From sleek corporate websites to vibrant creative portfolios, our designs are tailored to reflect your identity and engage your visitors.',
    // },
    // {
    //     id: 2,
    //     header: 'E-commerce Solutions',
    //     content: "In the ever-evolving digital marketplace, having a robust e-commerce platform is essential. We specialize in developing e-commerce websites that are secure, scalable, and user-centric, offering seamless shopping experiences. Whether you're a startup or an established retailer, our solutions are designed to boost your online sales and streamline your operations.",
    // },
    {
        id: 3,
        header: 'Responsive Web Design',
        content: "With the growing diversity of devices and screen sizes, a responsive website is no longer optional; it's a necessity. Our web development approach ensures that your site delivers optimal viewing experiences across all devices, from desktops to smartphones. We prioritize usability, speed, and design to ensure your website is accessible and engaging for every user, regardless of their device.",
    },
    {
        id: 4,
        header: 'Web Application Development',
        content: "Beyond traditional websites, we excel in developing dynamic web applications with complex functionalities and real-time features. Whether it's a custom portal, an online tool, or a web-based software solution, our team has the expertise to bring your ideas to life. We focus on creating applications that are powerful, scalable, and aligned with your business processes.",
    },
    {
        id: 5,
        header: 'Search Engine Optimization (SEO)',
        content: "A great website deserves to be seen. Our web development process incorporates SEO best practices to enhance your site's visibility and ranking on search engines. From keyword-optimized content to technical SEO elements, we work to increase your organic traffic and connect you with your target audience.",
    },
    {
        id: 6,
        header: 'Ongoing Support and Maintenance',
        content: "Our relationship with you doesn't end with the launch of your website. We offer ongoing support and maintenance services to ensure your site remains updated, secure, and aligned with evolving web standards. Whether it's updating content, implementing new features, or conducting regular performance checks, we're here to support your online success.",
    },
]