import Footer from "../components/Footer";
import aboutImg from '../assets/images/about-img.png';
import CTA from "../components/CTA";
import { NavLink } from 'react-router-dom';

const About = () => {
    return (
        <section className='min-h-screen bg-custom-about pt-44 lg:pt-32'>
        <div className="py-0 lg:py-5 px-5 lg:px-24">
            <h1 className="text-3xl font-bold">About Us</h1>
            <p className="mt-5">Welcome to SpaceWeb, where innovation meets excellence in web development and software testing. Since our inception in 2022, we have been at the forefront of creating digital solutions that empower businesses, enhance user experiences, and drive technological advancement.</p>
        </div>
        <img 
            src={aboutImg} 
            alt="" 
            className="w-full my-7"
            />

        <div className="mt-10 flex flex-col gap-10 lg:flex-row px-5 lg:px-24">
            <div>
                <h2>Our Mission</h2>
                <p>Our mission is to deliver exceptional web development and testing services that exceed our clients' expectations. We aim to transform ideas into digital realities that propel businesses forward, leveraging cutting-edge technologies and methodologies to create solutions that are not only innovative but also scalable and sustainable.</p>
            </div>
            <div>
                <h2>Our Vision</h2>
                <p>We envision a world where every business can thrive digitally. Our vision is to be the leading provider of web development and software testing services, recognized for our commitment to quality, innovation, and customer satisfaction. We strive to push the boundaries of what is possible, setting new standards in the digital landscape.</p>
            </div>
        </div>

        <div className="mt-10 px-5 lg:px-24">
            <h2>Our Values</h2>
            <ul >
                <li><strong>Innovation:</strong> We believe in the power of innovation to solve complex problems and create new opportunities.</li>
                <li><strong>Quality:</strong> Excellence is our benchmark. We are dedicated to delivering the highest quality in everything we do.</li>
                <li><strong>Integrity:</strong> We conduct our business with the utmost integrity, building trust with our clients, partners, and team members.</li>
                <li><strong>Collaboration:</strong> Success is a team effort. We foster a collaborative environment where ideas can flourish and everyone's contribution is valued.</li>
                <li><strong>Customer Focus:</strong> Our clients are at the heart of our business. We are committed to understanding their needs and delivering solutions that exceed their expectations.</li>
            </ul>
        </div>

        <div className="mt-10 px-5 lg:px-24">
            <h2>Our Journey</h2>
            <p>Over the years, we have had the privilege of working with diverse clients from various industries, delivering projects that range from simple websites to complex software applications. Each project has been a step towards realizing our vision, driven by our mission and guided by our values.
                <br />
                At SpaceWeb, we are more than just a company; we are a community of like-minded individuals passionate about technology and its potential to change the world. We invite you to join us on this exciting journey as we continue to innovate, create, and deliver digital excellence.</p>
        </div>

        <div className="my-7">
        <NavLink to='/faq'>
            <CTA text='Questions?'/>
        </NavLink>
        </div>

        <Footer />
        
        </section>
    );
};

export default About;

