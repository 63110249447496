export const dataFAQ =[
    {
        id: 1,
        question: "What services does SpaceWeb offer?",
        answer: "- We specialize in web development, software testing, UI/UX design, mobile app development, and digital marketing. Our aim is to provide comprehensive digital solutions to meet your business needs."
    },
    {
        id: 2,
        question: "How does the project process work?",
        answer: "- Our project process typically involves an initial consultation to understand your requirements, followed by the planning and strategy phase, design and development, testing for quality assurance, and finally, deployment and support."
    },
    {
        id: 3,
        question: "How long does it take to complete a web development project?",
        answer: "- The timeline for a web development project can vary depending on its complexity, features, and revisions. On average, a basic website might take 4-6 weeks, while more complex projects could take several months."
    },
    {
        id: 4,
        question: "Can you redesign my existing website?",
        answer: "- Absolutely! We can evaluate your current website and offer a redesign that improves its look, functionality, and performance, aligning it with the latest web standards and your business goals."
    },
    {
        id: 5,
        question: "Do you offer ongoing support and maintenance?",
        answer: "- Yes, we offer ongoing support and maintenance services to ensure your website or application remains up-to-date, secure, and performs optimally."
    },
    {
        id: 6,
        question: "How much do your services cost?",
        answer: '- Our pricing depends on the specific services you require, the complexity of your project, and the time frame. We offer customized quotes after understanding your project requirements in detail.'
    },
    {
        id: 7,
        question: "Do you outsource your work?",
        answer: "- Our core team handles the majority of our work to maintain high-quality standards. However, for specialized tasks or additional resources, we might collaborate with trusted partners who share our commitment to quality."
    },
    {
        id: 8,
        question: "Will my website be mobile-friendly?",
        answer: "- Yes, we ensure that all websites we develop are responsive and mobile-friendly, providing an optimal user experience across all devices."
    },
    {
        id: 9,
        question: "How do you ensure the quality of your services?",
        answer: "- We adhere to strict quality assurance processes, including thorough testing and reviews at every stage of development. Our team stays updated with the latest industry standards and technologies to deliver high-quality solutions."
    },
    {
        id: 10,
        question: "Can I be involved in the design process?",
        answer: "- Definitely! We encourage client involvement and feedback throughout the design process to ensure the final product aligns with your expectations and objectives."
    },
    {
        id: 11,
        question: "What industries do you specialize in?",
        answer: "- We have experience working across various industries, including retail, healthcare, finance, education, and technology. Our team is adept at understanding different industry challenges and creating tailored solutions."
    },
    {
        id: 12,
        question: "How do you handle data security and privacy?",
        answer: '- Data security and privacy are paramount in our development and testing processes. We follow best practices for data protection, including secure coding practices, data encryption, and compliance with relevant regulations.'
    },
    {
        id: 13,
        question: "Will I own the source code after the project is completed?",
        answer: "- Yes, upon project completion and final payment, you will own the source code and have full rights to it."
    },
    {
        id: 14,
        question: "How do we communicate during the project?",
        answer: "- We establish clear communication channels from the start, including email, phone calls, and project management tools, to keep you updated and involved throughout the project."
    },
    {
        id: 15,
        question: "What makes SpaceWeb different from other digital service providers?",
        answer: "- Our commitment to quality, client-centric approach, and comprehensive service offerings set us apart. We focus on delivering tailored solutions that not only meet but exceed your expectations, backed by our expertise and dedication to your success."
    },
]