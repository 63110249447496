import React from 'react'

const CTA = ({ text }) => {
  return (
    <div className='bg-gradient-to-r from-[#FA5AA5] to-[#612ABA] rounded-full flex justify-center mx-auto w-64 py-2 px'>
        <button className='text-white font-semibold py-4 text-xl bg-black rounded-full px-16'>
            {text}
        </button>
    </div>

  )
}

export default CTA;